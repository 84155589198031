import { motion } from 'framer-motion';
import { animations } from '../lib/animation-config';

export function FilterUpdateIndicator() {
  return (
    <motion.div 
      className="flex flex-col items-center justify-center gap-3 w-full h-full"
      initial={{ opacity: 0, scale: 1 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 1 }}
      transition={{ duration: animations.loading.duration }}
    >
      <motion.div 
        className="relative"
        animate={{ 
          rotate: 360
        }}
        transition={{
          duration: 1.5,
          repeat: Infinity,
          ease: "linear"
        }}
      >
        <svg 
          className="h-16 w-16 drop-shadow-md text-[#0f2c7d] dark:text-[#5c83ff]"
          xmlns="http://www.w3.org/2000/svg" 
          fill="none" 
          viewBox="0 0 24 24"
        >
          <circle 
            className="opacity-25" 
            cx="12" 
            cy="12" 
            r="10" 
            stroke="currentColor" 
            strokeWidth="4"
          />
          <path 
            className="opacity-90" 
            fill="currentColor" 
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      </motion.div>
      <p className="text-base font-medium text-[#0f2c7d] dark:text-[#5c83ff] drop-shadow-sm"      >
        Loading listings...
      </p>
    </motion.div>
  )
}