import { useState, useRef, useEffect, useCallback } from 'react';

/**
 * Custom hook to calculate the available height for accordion content in the filter modal
 * @param isOpen Whether the accordion is currently open
 * @param title The title of the accordion (used to identify it among other accordions)
 * @returns Object containing maxHeight and refs for the accordion elements
 */
export const useAccordionHeight = (isOpen: boolean, title: string) => {
  const [maxHeight, setMaxHeight] = useState<string>('100vh');
  const accordionRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  
  const calculateMaxHeight = useCallback(() => {
    if (!isOpen || !accordionRef.current) return;
    
    // Get viewport height (this will change on mobile when browser UI appears/disappears)
    const viewportHeight = window.innerHeight;
    
    // Get the modal container
    const modalContainer = accordionRef.current.closest('.drawer-container');
    if (!modalContainer) return;
    
    // Get references to relevant elements
    const modalHeader = modalContainer.querySelector('.drawer-header');
    const modalFooter = modalContainer.querySelector('.drawer-footer');
    const accordionHeaders = modalContainer.querySelectorAll('.accordion-header');
    
    // Calculate height of fixed elements
    let fixedElementsHeight = 0;
    
    // Add header height
    if (modalHeader) {
      fixedElementsHeight += modalHeader.getBoundingClientRect().height;
    }
    
    // Add footer height
    if (modalFooter) {
      fixedElementsHeight += modalFooter.getBoundingClientRect().height;
    }
    
    // Add heights of other accordion headers
    let otherHeadersHeight = 0;
    accordionHeaders.forEach((header) => {
      // Skip the current accordion's header
      if (header.textContent?.includes(title)) return;
      otherHeadersHeight += header.getBoundingClientRect().height;
    });
    fixedElementsHeight += otherHeadersHeight;
    
    // Add extra padding for safety
    fixedElementsHeight += 56;
    
    // Calculate max height
    const calculatedMaxHeight = viewportHeight - fixedElementsHeight;
    
    // Set minimum height to avoid extremely small accordions
    const finalHeight = Math.max(calculatedMaxHeight, 200);
    
    setMaxHeight(`${finalHeight}px`);
  }, [isOpen, title]);
  
  // Watch for window resize events
  useEffect(() => {
    if (!isOpen) return;
    
    // Initial calculation
    calculateMaxHeight();
    
    // Listen for resize and scroll events (to handle mobile browser UI)
    const handleResize = () => {
      // Use requestAnimationFrame to throttle calculations
      window.requestAnimationFrame(calculateMaxHeight);
    };
    
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);
    };
  }, [isOpen, calculateMaxHeight]);
  
  // Watch for changes in accordion open state
  useEffect(() => {
    if (isOpen) {
      // Schedule the calculation after the accordion opens to allow for animation to settle
      setTimeout(calculateMaxHeight, 100);
    }
  }, [isOpen, calculateMaxHeight]);
  
  return { maxHeight, accordionRef, contentRef, headerRef };
}; 