import { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ListingDetail } from './ListingDetail';
import { getScrollbarWidth } from '../utils/scrollbarWidth';

interface ListingDetailModalProps {
  isOpen: boolean;
  listingId: string;
  onClose: (event: CustomEvent) => void;
  sourceCardRect?: DOMRect | null;
}

export const ListingDetailModal = ({ 
  isOpen, 
  listingId, 
  onClose,
  sourceCardRect
}: ListingDetailModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  
  // Track animation state to prevent conflicts
  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
      // Clear animation flag after animation completes
      const timer = setTimeout(() => {
        setIsAnimating(false);
      }, 800); // Match this to your animation duration
      return () => clearTimeout(timer);
    } else if (!isOpen && modalRef.current) {
      // Ensure overflow is hidden when the modal starts closing
      modalRef.current.style.overflow = 'hidden';
      
    }
  }, [isOpen]);
  
  // Prevent scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      // Check if we actually have a scrollbar before applying compensation
      const hasScrollbar = document.documentElement.scrollHeight > document.documentElement.clientHeight;
      // Get cached scrollbar width from our utility
      const scrollbarWidth = hasScrollbar ? getScrollbarWidth() : 0;
      
      // Disable scrolling on html
      document.documentElement.style.overflow = 'hidden';
      
      // Only add margin if there was a scrollbar
      if (hasScrollbar && scrollbarWidth > 0) {
        document.documentElement.style.marginRight = `${scrollbarWidth}px`;
      }
      
      // Restore scrolling when component unmounts or modal closes
      return () => {
        document.documentElement.style.overflow = 'auto';
        document.documentElement.style.marginRight = '0px';
      };
    }
  }, [isOpen]);

  // Handle escape key to close the modal
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen && !isAnimating) {
        onClose(new CustomEvent('closeDetailModal', {
          detail: { fromBackButton: false }
        }));
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isOpen, onClose, isAnimating]);

  // Get animation parameters based on the source rect
  const getAnimationProps = () => {
    if (!sourceCardRect) {
      // Default animation if no source rect
      return {
        initial: { opacity: 0, scale: 1 },
        animate: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 1 },
        transition: { type: 'tween' }
      };
    }

    // Get viewport dimensions
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Calculate starting position relative to the viewport center
    const startX = sourceCardRect.left + (sourceCardRect.width / 2) - (viewportWidth / 2);
    const startY = sourceCardRect.top + (sourceCardRect.height / 2) - (viewportHeight / 2);
    
    // Calculate starting scale based on card width vs modal width
    const startScale = sourceCardRect.width / viewportWidth;
    
    return {
      initial: { 
        opacity: 0,
        x: startX, 
        y: startY,
        scale: startScale
      },
      animate: { 
        opacity: 1,
        x: 0, 
        y: 0,
        scale: 1,
      },
      exit: { 
        opacity: 0,
        x: startX, 
        y: startY,
        scale: startScale,
        transition: { 
          type: 'tween',
          duration: 0.8,
          ease: 'easeInOut'
        }
      },
      transition: { 
        type: 'tween'
      }
    };
  };

  // Get backdrop animation
  const backdropAnimation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.3 }
  };

  // Safe close handler to prevent closing during animation
  const handleClose = () => {
    if (!isAnimating) {
      onClose(new CustomEvent('closeDetailModal', {
        detail: { fromBackButton: false }
      }));
    }
  };

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <motion.div 
          className="fixed inset-0 z-[100] flex items-center justify-center"
          {...backdropAnimation}
          onClick={handleClose}
        >
          <motion.div
            ref={modalRef}
            className="fixed inset-0 bg-white dark:bg-neutral-900 z-[100] overflow-hidden"
            style={{
                paddingRight: `${getScrollbarWidth()}px`
            }}
            {...getAnimationProps()}
            onClick={(e) => e.stopPropagation()}
            onAnimationStart={() => setIsAnimating(true)}
            onAnimationComplete={() => {
              setIsAnimating(false);
              // Only enable scrolling after animation completes
              if (isOpen && modalRef.current) {
                modalRef.current.style.overflow = 'auto';
                modalRef.current.style.paddingRight = '0px';
              }
            }}
          >
            <ListingDetail 
              isModal={true} 
              onModalClose={() => {
                // Set overflow to hidden before closing animation begins
                if (modalRef.current) {
                  modalRef.current.style.overflow = 'hidden';
                }
                
                // Call the onClose prop directly - this will invoke 
                // the handleCloseDetailModal function from AircraftSearch
                // We need to create a synthetic event to match the expected parameter
                const isBackNavigation = window.history.state && 
                                      window.history.state.isBackNavigation === true;
                
                // Create a custom event-like object with the required structure
                const customEventData = {
                  detail: { 
                    fromBackButton: isBackNavigation 
                  }
                };
                
                // Call onClose with our custom event data
                onClose(customEventData as any);
              }} 
              listingId={listingId} 
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}; 